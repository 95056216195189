import React from 'react';
import Header from './header';
//import hero1 from '../images/hero-perfect-day.jpeg';
import hero1 from '../images/hero3.jpg';
import logo from '../images/perfect-day-events.png';
import ReactTypingEffect from '../vendor/react-typing-effect/src/lib';

function Hero({ innerPage, headerText }) {
  return (
    <div
      className="hero"
      style={{
        backgroundImage: `url(${hero1})`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Header headerText={headerText} />
      {/* <div className={`container-fluid ${innerPage ? `inner-page` : ``}`}> */}
      <div className={`container-fluid`}>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6 text-center">
            <div className="hero-image-container pb-2 d-none d-md-block">
              <img
                alt=""
                src={logo}
                className="px-5 pb-2 img-fluid img-hero-logo"
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 py-3 h3 text-left">
            <div className="type-text-container rounded my-3">
              <span>We </span>
              {typeof window !== 'undefined' ? (
                <ReactTypingEffect
                  className="type-text"
                  speed={100}
                  text={[
                    'are Perfect Day Events',
                    'are event planners',
                    'are entertainers',
                    'are photographers',
                    'are videographers',
                    'are party planners',
                    'are wedding planning',
                    'are your event partners',
                  ]}
                />
              ) : null}
            </div>
            <div className="sub-text ml-2 text-center text-lg-left d-none d-md-block">
              <h2 className="mt-4 pt-2">
                We are based in <br />
                <span className="text-center text-lg-right font-weight-bold sub-text-main">
                  {' '}
                  Cochin
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-image-container text-center pb-2 d-block d-md-none">
        <img alt="" src={logo} className="px-5 pb-2 img-fluid img-hero-logo" />
      </div>
    </div>
  );
}

export default Hero;
