/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './layout.css';
import Hero from './hero';

const Layout = ({ children, innerPage = true, headerText }) => {
  return (
    <div
      className={
        'container-fluid p-0 ' + (innerPage === false ? '' : 'inner-page')
      }
    >
      <Hero innerPage={innerPage} headerText={headerText} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer className="small">
          © {new Date().getFullYear()}, Perfect Day Events - we make <i>Your</i>{' '}
          day perfect
        </footer>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
