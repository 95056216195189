import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import React from 'react';
import { Link } from 'gatsby';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <div style={{ backgroundColor: '#FFFFFF30' }}>
        <Navbar color="navbar-light" light expand="md">
          <Link className="navbar-brand" to="/">
            <h1 className="h2 text-dark header-border">
              {this.props.headerText || ''}
            </h1>
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link className="nav-link" activeClassName="active" to="/">
                  Home
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeClassName="active"
                  to="/about-us/"
                >
                  About us
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeClassName="active"
                  to="/our-services/"
                >
                  Our Services
                </Link>
              </NavItem>

              <NavItem>
                <Link
                  className="nav-link"
                  to="/contact-us/"
                  activeClassName="active"
                >
                  Contact us
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;
